/**************************************************
 * Nombre:       Tarjeta_Rentabilidad
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import Barra_Radial from "../Graficas/Barra_Radial";
import {theme} from "../../../Tema";
import Indicador_Diferencian_Ano from "./Componentes/Indicador_Diferencian_Ano";
import Botones_Ano from "./Componentes/Botones_Ano";
import Titulo_Tarjeta from "./Componentes/Titulo_Tarjeta";
import Valor_Principal from "./Componentes/Valor_Principal";


const Tarjeta_Rentabilidad = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                border: '0.5px solid #EAEAEA',
                boxShadow: '0px 8px 12px 0px #00000014',
                borderRadius: 2,
                py: sCell ? 3 : 3,
                px: sCell ? 3 : 3,
            }}
        >

            <Grid item container lg={7} sm={7} xs={7} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Titulo_Tarjeta />
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                       <Valor_Principal/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                        <Indicador_Diferencian_Ano/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                        <Botones_Ano/>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={5} sm={5} xs={5} sx={{justifyContent: 'flex-start'}}>

                <Barra_Radial/>

            </Grid>


        </Grid>
    )

}
export default Tarjeta_Rentabilidad