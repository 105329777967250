/**************************************************
 * Nombre:       Seccion_Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     24 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {useState} from "react";
import {theme} from "../../Tema";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import Tarjeta_Rentabilidad from "../../Modulo_Graficas/Componentes/Tarejetas/Tarjeta_Rentabilidad";


const Seccion_Dashboard = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'cotizaciones',
        filtroInicial: 'fecha',
        nombreEstado: 'estado',
    })

    const botones = []

    const manejardorClicks = (row, id) => {


    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginBottom: 4}}>
                <TituloNotificaciones titulo={'Dashboard'} textoComplementario={''}
                                      textoResaltado={`Perido 2024`} botones={botones}/>
            </Grid>

            <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Tarjeta_Rentabilidad/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Dashboard

