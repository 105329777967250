/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import DashboardBase from "../Modulo_dashboard/Componentes/DashboardBase";
import MenuLateral from "../Modulo_dashboard/Componentes/MenuLateral";
import {
    DashboardOutlined,
    EngineeringOutlined,
    GroupOutlined,
    InventoryOutlined,
    ReportProblemOutlined,
    RequestPageOutlined,
    StickyNote2Outlined
} from "@mui/icons-material";
import logo_oscuro from '../Recursos/logo_oscuro.svg'
import logo_redondo from '../Recursos/logo_redondo_oscuro.svg'
import {funSalir} from "../Servicios/Auth/funSalir";
import {React, useContext} from "react";
import {usuarioContext} from "../App";
import Seccion_Clientes from "./Secciones/Seccion_Clientes";
import Seccion_Cotizaciones from "./Secciones/Seccion_Cotizaciones";
import Seccion_Ordenes from "./Secciones/Seccion_Ordenes";
import Seccion_Cartera from "./Secciones/Seccion_Cartera";
import Seccion_Pqrs from "./Secciones/Seccion_Pqrs";
import Seccion_Tickets from "./Secciones/Seccion_Tickets";
import {funDesSuscribirTopic} from "../M_Notificaciones/Funciones/funDesSuscribirTopic";
import Seccion_Dashboard from "./Secciones/Seccion_Dashboard";

const Dashboard = () => {
    const cData = useContext(usuarioContext)

    const click = () => {
        alert('click en perfil')
    }

    const salirPlataforma = () => {
        funDesSuscribirTopic({
            token: cData.usuario.token,
            topic: 'admin',
            key: 'AAAAX6dxla4:APA91bHkrsHc0702NFYoT8eZLvwarPtlWMYKuWo-D-JZ0qewfgJDvKbqMeH5AqFmj7H1hBcBRqKJlo4chDDDjEFMRVPw6vS3PhJTQQGa0zLo15zKS8wPDgtJ6WSmkdBr_vq9kndgQvBu'
        })
        funSalir()

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <DashboardBase
                secciones={secciones}>
                <MenuLateral logo={logo_oscuro} usuario={cData.usuario} clickPerfil={click} logoRedondo={logo_redondo}
                             salir={salirPlataforma}
                             logoBlanco={logo_oscuro}/>
            </DashboardBase>


        </Grid>
    )

}
export default Dashboard

const secciones = [

    {
        nombre: 'Dashboard',
        Componente: <Seccion_Dashboard/>,
        icono: DashboardOutlined,
        categoria: 'propia',
        camino: '/Dashboard',
    },
    {
        nombre: 'Cotizaciones',
        Componente: <Seccion_Cotizaciones/>,
        icono: InventoryOutlined,
        categoria: 'finanzas',
        camino: '/',
    },
    {
        nombre: 'Ordenes de Servicio',
        Componente: <Seccion_Ordenes/>,
        icono: EngineeringOutlined,
        categoria: 'finanzas',
        camino: '/Ordenes',
    },
    {
        nombre: 'Cartera',
        Componente: <Seccion_Cartera/>,
        icono: RequestPageOutlined,
        categoria: 'finanzas',
        camino: '/cartera',
    },
    {
        nombre: 'Clientes',
        Componente: <Seccion_Clientes/>,
        icono: GroupOutlined,
        categoria: 'listas',
        camino: '/Clientes',
    },
    {
        nombre: 'Tickets',
        Componente: <Seccion_Tickets/>,
        icono: StickyNote2Outlined,
        categoria: 'listas',
        camino: '/Tickets',
    },
    {
        nombre: 'Pqrs',
        Componente: <Seccion_Pqrs/>,
        icono: ReportProblemOutlined,
        categoria: 'listas',
        camino: '/Pqrs',
    },


]

