/**************************************************
 * Nombre:       Botones_Ano
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {FiberManualRecord} from "@mui/icons-material";

const Botones_Ano = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                <FiberManualRecord sx={{width: 14, height: 14, marginTop: 0.2, fill: '#0083F7'}} /> <Typography sx={{pl:0.5, fontSize: 12, fontWeight: 700}} >  2024</Typography>
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                <FiberManualRecord sx={{width: 14, height: 14, marginTop: 0.2, fill: '#D9D9D9'}} /> <Typography sx={{pl:0.5, fontSize: 12, color: '#52657C', fontWeight: 500}} >  2025</Typography>
            </Grid>

        </Grid>
    )

}
export default Botones_Ano