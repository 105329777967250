/**************************************************
 * Nombre:       Valor_Principal
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";

const Valor_Principal = () => {

    return (
        <Typography sx={{fontWeight: 600, fontSize: 24}}>$ 36.358</Typography>
    )

}
export default Valor_Principal