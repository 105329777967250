/**************************************************
 * Nombre:       Seccion_Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     24 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {Edit, InventoryOutlined, Outbound, PriceChange, TaskAlt,} from "@mui/icons-material";
import {theme} from "../../Tema";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import Dialogo_Cotizacion_Ingresar_Items from "../Dialogos/Dialogo_Cotizacion_Ingresar_Items";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import {contarPropiedadValor} from "../../Servicios/BD/Contadores/contarPropiedadValor";
import {contarPropiedadSumaMes} from "../../Servicios/BD/Contadores/contarPropiedadSumaMes";
import {obtenerMesYearAnterior} from "../../Modulo_Graficas/Utilidades/obtenerMesYearAnterior";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import Dialogo_Cotizacion_Descuentos from "../Dialogos/Dialogo_Cotizacion_Descuentos";
import Dialogo_Cotizacion_Aprobar from "../Dialogos/Dialogo_Cotizacion_Aprobar";
import Dialogo_Cotizacion_Incrementos from "../Dialogos/Dialogo_Cotizacion_Incrementos";
import {ESTADOSCOTIZACION} from "../../Constantes";


const Seccion_Cotizaciones = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'cotizaciones',
        filtroInicial: 'fecha',
        nombreEstado: 'estado',
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Cotizacion_Ingresar_Items,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalles de cliente'
    })

    const {Dialogo: DialogoIncremento, abrir: abrirIncremento} = useDialogo({
        Componente: Dialogo_Cotizacion_Incrementos,
        realizado: () => setRecargar(!recargar),
        titulo: 'Incrementos a cotizacion'
    })

    const {Dialogo: DialogoProcesar, abrir: abrirProcesar} = useDialogo({
        Componente: Dialogo_Cotizacion_Descuentos,
        realizado: () => setRecargar(!recargar),
        titulo: 'Descuentos y cortesias'
    })
    const {Dialogo: DialogoDespachar, abrir: abrirDespachar} = useDialogo({
        Componente: Dialogo_Cotizacion_Aprobar,
        realizado: () => setRecargar(!recargar)
    })

    const [valoresPedidos, setValoresPedidos] = useState([])

    const botones = [
        <Button
            color={'primary'}
            onClick={() => abrir()}
            startIcon={<InventoryOutlined/>}
            variant={'contained'}>
            Nueva
        </Button>
    ]

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'editar' :
                abrir(row)
                break
            case 'incrementos' :
                abrirIncremento(row)
                break
            case 'descuentos' :
                abrirProcesar(row)
                break
            case 'aprobar' :
                abrirDespachar(row)
                break
        }

    }
    const llenarValores = () => {

        let hoy = new Date()
        let mes = hoy.getMonth()
        let year = hoy.getFullYear()

        let valorHoy = 0
        let valorAnterior = 0

        let costosHoy = 0
        let costoAnterior = 0

        let fechaAnterior = obtenerMesYearAnterior({year: year, mes: mes})


        contarPropiedadSumaMes({coleccion: 'cotizaciones', propiedad: 'total', mes: mes, year: year}).then((dox) => {
            if (dox.res) {
                valorHoy = dox.data

                contarPropiedadSumaMes({
                    coleccion: 'cotizaciones',
                    propiedad: 'total',
                    mes: fechaAnterior.mes,
                    year: fechaAnterior.year
                }).then((dox) => {
                    if (dox.res) {
                        valorAnterior = dox.data

                        let valoresHoy = adaptador_indicador({
                            titulo: 'Valor Cotizado',
                            valorAnterior: valorAnterior,
                            valorActual: valorHoy,
                            detalle: 'vs mes anterior'
                        })


                        contarPropiedadSumaMes({
                            coleccion: 'cotizaciones',
                            propiedad: 'costo',
                            mes: mes,
                            year: year
                        }).then((dox) => {
                            if (dox.res) {
                                costosHoy = dox.data

                                contarPropiedadSumaMes({
                                    coleccion: 'cotizaciones',
                                    propiedad: 'costo',
                                    mes: fechaAnterior.mes,
                                    year: fechaAnterior.year
                                }).then((dox) => {
                                    if (dox.res) {
                                        costoAnterior = dox.data

                                        let gananciasPotencialesHoy = adaptador_indicador({
                                            titulo: 'Gancia Potencial',
                                            valorAnterior: valorAnterior - costoAnterior,
                                            valorActual: valorHoy - costosHoy,
                                            detalle: 'vs mes anterior'
                                        })

                                        setValoresPedidos([valoresHoy, gananciasPotencialesHoy])

                                    }
                                })


                            }
                        })

                    }
                })


            }
        })


    }

    useEffect(() => {

        contarPropiedadValor({coleccion: 'cotizaciones', propiedad: 'estado', valor: 'Nueva'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
                console.log(dox.data)
            }
        })

        llenarValores()

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <DialogoIncremento/>

            <DialogoProcesar/>

            <DialogoDespachar/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Cotizaciones'} textoComplementario={'nuevas'}
                                      textoResaltado={`${numProductos} Cotizaciones`} botones={botones}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Indicadores valores={valoresPedidos} dir={'flex-end'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabeceraPc}
                    {...props}
                    click={manejardorClicks}
                    estados={ESTADOSCOTIZACION}
                    botonesTabla={botonesTabla}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Cotizaciones

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Ciudad',
        propiedad: 'ciudad',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabeceraPc = [
    {
        nombre: 'Num',
        direccion: 'center',
        propiedad: 'consecutivo',
        tipo: 'numero'
    },
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'
    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }, {
        nombre: 'Justificacion',
        direccion: 'right',
        propiedad: 'justificacion',

    }, {
        nombre: 'Total',
        direccion: 'right',
        propiedad: 'total',
        tipo: 'moneda'
    },

]

const datosCabeceraCell = [
    {
        nombre: 'Num',
        direccion: 'center',
        propiedad: 'consecutivo',
        tipo: 'numero'
    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }
]

const botonesTabla = [
    {
        nombre: 'Editar',
        icono: Edit,
        id: 'editar'
    },
    {
        nombre: 'Incrementos',
        icono: Outbound,
        id: 'incrementos'
    },
    {
        nombre: 'Descuentos',
        icono: PriceChange,
        id: 'descuentos'
    },
    {
        nombre: 'Aprobar',
        icono: TaskAlt,
        id: 'aprobar'
    },
]

