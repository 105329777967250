/**************************************************
 * Nombre:       Titulo_Tarjeta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";

const Titulo_Tarjeta = () => {

    return (
        <Typography sx={{fontWeight: 600, fontSize: 16}}>Retabilidad</Typography>
    )

}
export default Titulo_Tarjeta