/**************************************************
 * Nombre:       Indicador_Diferencian_Ano
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, IconButton, Typography} from "@mui/material";
import {CallMade} from "@mui/icons-material";

const Indicador_Diferencian_Ano = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >

            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start'}}>
                <IconButton sx={{backgroundColor: '#DCFFFA', p: 0.5}}><CallMade sx={{width: 14, height: 14, fill: '#00d2b2'}}/></IconButton>
            </Grid>

            <Grid item container lg={9} sm={9} xs={9} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{fontSize: 14, fontWeight: 600}} > 19% <span style={{marginLeft: 5, fontWeight: 500, color: '#52657C'}}>año anterior</span></Typography>
            </Grid>

        </Grid>
    )

}
export default Indicador_Diferencian_Ano