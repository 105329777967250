/**************************************************
 * Nombre:       Barras_Horizontales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Chart from "react-apexcharts";
import {useRef} from "react";
import {useObtenerAncho} from "../../Hooks/useObtenerAncho";

const Barra_Radial = () => {
    const refe = useRef()
    const {ancho} = useObtenerAncho({contenedor: refe})

    const data = [75]

    const opciones = {

        chart: {
            id: "basic-bar",
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        colors: ["#427EF7"],
        plotOptions: {
            radialBar: {
                offsetY: 3,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: -4,
                    size: '120%',
                },
                track: {
                    show: true,
                    startAngle: undefined,
                    endAngle: undefined,
                    background: '#E7F0FE',
                    strokeWidth: '100%',
                    opacity: 1,
                    margin: -20,
                    dropShadow: {
                        enabled: false,
                        top: 0,
                        left: 0,
                        blur: 3,
                        opacity: 0.5
                    }
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: 6,
                        fontSize: 16,
                        show: true,
                        fontWeight: 500,
                    },
                }
            },

        },
        stroke: {
            lineCap: "butt",
        },

    }


    return (
        <Grid item ref={refe} sx={{}}>

            <Chart
                options={opciones}
                series={data}
                type="radialBar"
                width={ancho}
            />
        </Grid>
    )

}
export default Barra_Radial